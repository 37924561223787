import { Accordion } from '@almbrand/accordion';
import { RichTextComponent } from 'components/1-atoms';

interface AccordionItem {
	question: string;
	answer: string;
	isNew: boolean;
	showLastUpdated: boolean;
	lastUpdatedText: string;
	contentType: string;
	contentId: number;
}

interface AccordionGroup {
	title?: string;
	contentType: string;
	contentId: number;
	accordionItems: {
		version: number;
		items: {
			content: AccordionItem;
		}[];
	};
}

interface AccordionComponentProps extends LocalId {
	isFaqAccordions?: boolean;
	header?: string;
	accordionsArea: {
		groups?: AccordionGroup[];
		items?: AccordionItem[];
	};
	style?: string;
	padding?: boolean;
	ctaBlock?: CTABlock;
	backgroundColor?: BackgroundColor;
	itemsBackgroundColor?: BackgroundColor;
	showUsabillaInPage?: boolean;
	contentId?: string | number;
}

export const AccordionComponent: React.FC<AccordionComponentProps> = ({
	// isFaqAccordions,
	// header,
	accordionsArea,
	// ctaBlock,
	// style,
	// padding,
	// backgroundColor,
	// itemsBackgroundColor,
	// showUsabillaInPage,¨
	header,
	useReadline,
	readlineId,
	contentId,
}) => {
	const { groups, items } = accordionsArea;
	return (
		<>
			{header && (
				<div className={'AccordionComponent__header'}>
					<RichTextComponent richText={header} />
				</div>
			)}
			{groups?.length
				? groups.map((group, index) => (
						<div key={index} className={'AccordionComponent__groupSpacing'}>
							<Accordion
								header={group.title}
								items={group.accordionItems.items.map((item, itemIndex) => ({
									id: itemIndex,
									title: item.content.question,
									content: item.content.answer,
									isActive: false,
								}))}
								id={useReadline && readlineId ? readlineId : contentId}
							/>
						</div>
					))
				: items.map((item, itemIndex) => (
						<Accordion
							items={[
								{
									id: itemIndex,
									title: item.question,
									content: item.answer,
									isActive: false,
								},
							]}
							key={itemIndex}
							id={useReadline && readlineId ? readlineId : contentId}
						/>
					))}
		</>
	);
};
