import { Chatbot } from '@almbrand/chatbot';

export const ChatbotComponent: React.FC = () => {
	return (
		<Chatbot
			chatbotLabel={'Spørg mig'}
			chatbotApiUrl={'https://codanekstern.boost.ai/api'}
			icon={{
				iconProp: { themeName: 'Codan-theme', icon: 'bell' },
			}}
			storybook={false}
		/>
	);
};
