import { GridCell } from '@almbrand/grid';
import { Component } from 'components/Component';

export const GridCellComponent: React.FC<{ component: ContentApiModel; className?: string }> = ({
	component,
	className,
}) => {
	const { gridSize, tabletGridSize, mobileGridSize, gridMove, mobileGridMove, tabletGridMove } =
		component?.grid ?? {};

	return (
		<GridCell
			desktopWidth={gridSize}
			tabletWidth={tabletGridSize}
			mobileWidth={mobileGridSize}
			desktopOffset={gridMove}
			tabletOffset={mobileGridMove}
			mobileOffset={tabletGridMove}
			className={className}
		>
			<Component {...component?.content} />
		</GridCell>
	);
};
