import { Container } from '@almbrand/container';
import { Grid } from '@almbrand/grid';
import classNames from 'classnames';
import { Component } from 'components/Component';
import { GridCellComponent } from './GridCellComponent';

export const ContainerLayout: React.FC<{ component: ContentApiModel }> = ({ component }) => {
	const { style, content } = component ?? {};
	const { innerBackgroundColor, gridStyle, marginBottom, marginTop } = style ?? {};
	const { useReadline, readlineId, contentId } = content ?? {};

	if (content.contentType == 'ReadlineBlock') return <Component {...content} />;
	return (
		<Container
			desktopWidth="fullWidth"
			tabletWidth="fullWidth"
			mobileWidth="fullWidth"
			backgroundColor={innerBackgroundColor?.cssClass}
			id={useReadline && readlineId ? readlineId : contentId}
			className={classNames(`pb-${marginBottom}`, `pt-${marginTop}`)}
		>
			<Container desktopWidth="1600" tabletWidth="1280" mobileWidth="744">
				<Grid container={gridStyle === 'fullscreen'}>
					{content?.contentType === 'DefaultContainerBlock' ? (
						content?.mainArea?.items?.map((subItem, subIndex) => {
							return <GridCellComponent component={subItem} key={subIndex} />;
						})
					) : (
						<GridCellComponent component={component} />
					)}
				</Grid>
			</Container>
		</Container>
	);
};
