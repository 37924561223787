import { PageContext } from 'context/PageContext';
import { useContext } from 'react';

import { Container } from '@almbrand/container';
import { Grid, GridCell } from '@almbrand/grid';
import { ChatbotComponent, NotificationBanner } from 'components/2-molecules';
import { ContainerLayout, FooterComponent, HeaderComponent } from 'components/4-habitats';

export const ContentPage: React.FC<PageModel> = () => {
	const pageContext = useContext(PageContext);

	const { headerContent, ChatbotContent, content, footerContent, notificationBannerContent } = pageContext ?? {};

	return (
		<>
			<Container desktopWidth="fullWidth" tabletWidth="fullWidth" mobileWidth="fullWidth">
				<Container desktopWidth="1600" tabletWidth="1280" mobileWidth="744">
					<Grid>
						<GridCell>
							<HeaderComponent {...headerContent} />
						</GridCell>
					</Grid>
				</Container>
			</Container>

			{notificationBannerContent && (
				<Container desktopWidth="1600" tabletWidth="1280" mobileWidth="744">
					<Grid container>
						<GridCell>
							<NotificationBanner {...notificationBannerContent} />
						</GridCell>
					</Grid>
				</Container>
			)}

			{content?.items?.map((component) => (
				<ContainerLayout component={component} key={component?.content?.contentId} />
			))}

			<Container noPadding desktopWidth="fullWidth" tabletWidth="fullWidth" mobileWidth="fullWidth">
				<FooterComponent {...footerContent} />
				{!ChatbotContent && <ChatbotComponent />}
			</Container>
		</>
	);
};
