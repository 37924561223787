import { ButtonProps } from '@almbrand/button';
import { Hero } from '@almbrand/hero';

export interface HeroComponentProps extends LocalId {
	contentId: number;
	image?: Image;
	backgroundColor?: BackgroundColor;
	tagline?: string;
	textArea: string;
	ctaBlock?: CTABlock;
	countdownPositionTop?: boolean;
	dynamicTitle?: boolean;
	fontRatio?: string;
	paddingSides?: string;
	paddingTopBottom?: string;
}

// The HeroBanner component using directly passed props
export const HeroComponent: React.FC<HeroComponentProps> = ({
	contentId,
	image,
	backgroundColor,
	tagline,
	textArea,
	ctaBlock,
	useReadline,
	readlineId,
	// countdownPositionTop,
	// transparentBackgroundColor,
	// dynamicTitle,
	// fontRatio,
	// paddingSides,
	// paddingTopBottom,
}) => {
	const { properties, url } = image ?? {};
	const { imageFocalPoint: fp, altText } = properties ?? {};
	const { btnColor, btnType, link, title } = ctaBlock ?? {};

	const focusX = parseFloat(fp?.x) * 100;
	const focusY = parseFloat(fp?.y) * 100;

	const buttonType = btnType == 'cta' ? btnColor : btnType;

	return (
		<Hero
			id={useReadline && readlineId ? readlineId : contentId}
			image={{ imageSrc: url, objectPosition: `${focusX}% ${focusY}%`, altText: altText ?? 'Hero Block Image' }}
			backgroundColor={backgroundColor?.cssClass}
			tagline={tagline}
			content={textArea}
			button={{
				children: title,
				buttonType: buttonType as ButtonProps['buttonType'],
				linkButtonSettings: {
					href: link.url,
				},
			}}
		/>
	);
};
